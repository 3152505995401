<template>
    <div class="page">
        <div v-if="isReady">
            <div class="heading">
                <h1>小一面試班</h1>
                <!--<h2>第{{ (lesson).toLocaleString('zh-u-nu-hanidec') }}課</h2>-->
                <h2>{{ course_name }}</h2>
                <h3>{{ lesson_name }}</h3>
            </div>

            <template v-if="data.length > 0">
                <div class="paging">
                    <ul>
                        <li v-for="(p, index) in data" :key="index" :class="[( (index+1) == page ) ? 'active' : '']">
                            <div class="circle" @click="swapPage(index + 1)"><div class="number">{{ index + 1 }}</div></div>
                            <div class="name" @click="swapPage(index + 1)">{{ p.tc_name }}</div>
                        </li>
                    </ul>
                </div>

                <template v-for="(p, index) in data" :key="index">
                    <div class="content"  v-if="page == (index+1)">
                        <h1>{{ p.tc_name }}</h1>
                        
                        <div class="ql-container ql-snow">
                            <div class="ql-editor">
                                <div v-if="p.select_type == 'tc_description'" class="description" v-html="p.tc_description"></div>
                            </div>
                        </div>

                        <div v-if="p.select_type == 'pdf'">
                            <div v-for="(pdf, index) in p.pdf" :key="index" class="pdf-wrapper">
                                <div class="caption" v-if="pdf.caption !== ''">{{ pdf.caption }}</div>
                                <a :href="pdf.file" target="_blank">
                                    <div class="pdf">
                                        <embed :src="pdf.file + '#toolbar=0&navpanes=0&view=FitH'" type="application/pdf" ref="pdf">
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div v-if="p.select_type == 'photo'">
                            <div v-for="(photo, index) in p.photo" :key="index" class="photo-wrapper">
                                <div class="caption" v-if="photo.caption !== ''">{{ photo.caption }}</div>
                                <div class="photo">
                                    <img :src="photo.file" />
                                </div>
                            </div>
                        </div>

                        <div v-if="p.select_type == 'youtube'">
                            <div class="youtube">
                                <iframe :src="p.youtube" ></iframe>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <div class="content no-data" v-else>
                資料準備中，內容將稍後公佈。
            </div>
        </div>
        <div v-if="error">
            <div class="heading">
                <h1>小一面試班</h1>
                <h2>發生錯誤</h2>
            </div>
            <div class="content">{{ errorMessage }}</div>
        </div>
    </div>
</template>

<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
    data() {
        return {
            isReady: false,
            error: false,
            errorMessage: null,
            data: [],
            page: 1,
            lesson: '',
            course_name: '',
            lesson_name: ''
        }
    },
    mounted () {
        this.$root.updateNotification()
        const params = new FormData()
        if (this.$route.params.id) {
            params.append('id', this.$route.params.id)
        }
        params.append('token', this.Auth.getToken())

        this.$axios
            .post(process.env.VUE_APP_API + 'lesson' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success === true) {
                    this.data = r.data

                    this.course_name = r.course_name

                    this.lesson = r.lesson
                    this.lesson_name = r.lesson_name

                    this.isReady = true
                } else {
                    this.$router.push('/course/')
                    this.error = true
                }
            })
            .catch(e => {
                this.error = true
                this.errorMessage = e.message
            })

    },
    methods: {
        swapPage(index) {
            this.page = index
        }
    }
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
*::-webkit-scrollbar {
    height:12px;
    position:absolute; top:0px; left:0px; width:100vw;
}
*::-webkit-scrollbar-button { display:none; }
*::-webkit-scrollbar-thumb { background-color:#52d0ff; }
*::-webkit-scrollbar-track { background-color:#3ea6cd; }

.page {
    .heading {
        h1 { font-size:20px; margin-bottom:50px; }
        h2 { transform:scaleY(1.3); font-size:18px; }
        h3 {
            text-align:center;
            transform:scaleY(1.3); 
            font-size:22px; line-height:40px; 
            font-family:'Roboto', Helvetica, Arial, sans-serif; 
            padding:0px; margin:0px 0px 10px 0px; 
            color:#ffffff;
            text-shadow:0px 0px 6px rgba(0,0,0,0.35);
            letter-spacing:4px;
        }
    }

    .content {
        max-width:890px;
        width:90%;
        text-align:center;
        margin:30px auto;
        padding:20px 0px;

        .description {
            text-align:left;
            :deep(h2) { transform:scaleY(1.3); margin:0px; }
            :deep(p) { padding:0px; margin:0px; }
            :deep(ol) {
                margin:0px;
                margin-left:20px;
                padding:0px;
                li {
                    padding-bottom:10px;
                }
            }
            :deep(ul) {
                margin:0px;
                margin-left:20px;
                padding:0px;
                li {
                    padding-bottom:10px;
                }
            }
        }

        &.no-data {
            color:#eeee00;
            font-size:18px;
        }
    }

    .paging {
        margin:40px auto 20px auto;
        text-align:center;
        width:100%;
        box-sizing:border-box;
        position:relative;

        &:after {
            width:40px;
            min-height:calc(100% - 12px);
            background:linear-gradient(90deg, rgba(27,154,202,1) 0%, rgba(27,154,202,0%) 100%);
            position:absolute;
            top:0px;
            left:0px;
            content:'';
            z-index:1;
            pointer-events:none;
        }
        &:before {
            width:40px;
            min-height:calc(100% - 12px);
            background:linear-gradient(90deg, rgba(27,154,202,0) 0%, rgba(27,154,202,1) 100%);
            position:absolute;
            top:0px;
            right:0px;
            content:'';
            z-index:1;
            pointer-events:none;
        }

        ul {
            padding:0px 40px 20px 40px;
            box-sizing:border-box;
            list-style:none;
            white-space:nowrap;
            overflow-x:auto;
            li {
                display:inline-block;
                margin-right:50px;
                box-sizing:border-box;
                position:relative;
                width:120px;
                white-space:inherit;
                vertical-align:top;
                

                &.active {
                    .circle { opacity:1; }
                    .name { opacity:1; }
                }
                
                &:after {
                    background:url('~@/assets/arrow.png') no-repeat center center;
                    background-size:contain;
                    width:20px;
                    height:20px;
                    display:block;
                    position:absolute;
                    top:30px;
                    left:calc(100% + 10px);
                    content:'';
                    opacity:0.5;
                }
                &:last-child {
                    margin-right:0px;
                    &:after { display:none; }
                }
                .circle {
                    cursor:pointer;
                    opacity:0.5;
                    width:80px;
                    height:80px;
                    margin:0px auto 5px auto;
                    border-radius:40px;
                    background-color:rgba(82,208,255,1);
                    text-align:center;
                    .number {
                        font-family:"微軟正黑體", "Times";
                        line-height:80px;
                        font-size:40px;
                        display:block;
                        text-shadow:stroke(2px, #ffffff);
                        color:rgba(82,208,255,1);
                    }
                }
                .name {
                    cursor:pointer;
                    white-space:pre-wrap;
                    opacity:0.5;
                }
            }
        }
    }
}

.ql-container.ql-snow {
    border:0px;
    :deep(h1) { text-shadow:inherit; letter-spacing:inherit; }
    :deep(h2) { text-shadow:inherit; letter-spacing:inherit; }
    :deep(h3) { text-shadow:inherit; letter-spacing:inherit; }
}
@media only screen and (max-width:700px) {
    .page {
        .paging {
            ul {
                li {
                    width:100px;
                    .circle {
                        width:60px;
                        height:60px;
                        border-radius:30px;
                        .number {
                            line-height:60px;
                            font-size:30px;
                        }
                    }
                }
            }
        }
    }    
}

</style>
